import { useId } from "react";

import { CheckIcon } from "~/components/svg/icons";
import { cn } from "~/utils/classnames";

import "./Switch.css";
import type { SwitchProps } from "./Switch.types";

/**
 * Composant "interrupteur" d'activation / désactivation (semblable à une checkbox)
 */
export const Switch = ({
  id,
  label,
  disabled = false,
  defaultChecked,
  onChange,
  className,
  ...props
}: SwitchProps) => {
  const generatedId = useId();

  return (
    <div className={cn("Switch", className)}>
      {label ? (
        <label className="Switch-label" htmlFor={id || generatedId}>
          {label}
        </label>
      ) : null}
      <input
        id={id || generatedId}
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
        className="Switch-checkbox"
        defaultChecked={defaultChecked}
        {...props}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="Switch-container" htmlFor={id || generatedId}>
        <CheckIcon className="Switch-checkIcon svgIcon" />
        <span className="Switch-button" />
      </label>
    </div>
  );
};
